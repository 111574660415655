<template>
  <div
    v-if="$route.name === 'index'"
    class="w-block__footer"
    :class="{
      'w-block__footer--mobile': $voicer.isMobile,
      'w-block__footer--ios': $voicer.isIos,
      'w-block__footer--no-player': isPersistantVisible === false,
    }"
  >
    <!-- footer block -->
    <img
      :src="require('~/assets/images/logo-vinci.svg')"
      height="80"
      alt="vinci-construction"
      class="mr-4 pa-4"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
