<template>
  <div class="w-block__post-toolbar-1">
    <v-container v-if="$route.name === 'index'" class="pa-4">
      <v-sheet color="#E5F1F9" dark class="pa-2">
        <v-flex xs12>
          <div class="custom-color">
            Pour nous partager une actu de votre équipe, envoyez un SMS au
            <a href="sms:+33648737826">06 48 73 78 26</a> !
            <br />
            <v-btn
              class="white--text"
              color="primary"
              @click.stop="showSecondFeedbackModal = true"
            >
              Enregistrer un message
            </v-btn>
            <w-feedback-popup
              id="secondFeedback"
              v-model="showSecondFeedbackModal"
            />
          </div>
        </v-flex>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import WFeedbackPopup from '../components/WFeedbackPopup'
export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFirstFeedbackModal: false,
      showSecondFeedbackModal: false,
    }
  },
  methods: {
    handleOpen(e) {
      this.$spoke
        .collection('contents')
        .getOne('63fcb12f06470e7729aebfa1')
        .on('success', (content) => {
          this.$store.dispatch('player/changePlayerContent', {
            content,
            noRedirect: false,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__post-toolbar-1 {
  justify-content: center;
  text-align: center;
}
.v-btn__content {
  background-color: transparent;
}
.v-btn::before {
  background-color: transparent;
}
.v-card {
  border-radius: 8px;
}
.v-sheet {
  border-radius: 8px;
}
.custom-color {
  color: #000000;
}
.v-btn {
  border-radius: 4px;
}
</style>
